body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* h1 {
  font-size: 22px;
  font-weight: 500;
  color: rgba(21, 14, 55, 0.85)
}

h2 {
  font-size: 18px;
  font-weight: 500;
}

p {
  font-size: 16px;
  font-weight: 400;
}

.button {
  font-size: 16px;
  font-weight: 400;
}

.chip,.badge {
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  padding: 0px 12px;
  border-radius: 999px;
}

.label {
  font-size: 10px;
  font-weight: 600;
} */