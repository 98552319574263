.App {
  text-align: center;
  font-family: 'Poppins';
  color: var(--Dark_text, rgba(21, 14, 55, 0.85));
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.search-form {
  z-index: 101;
}

.route-summary {
  z-index: 101;
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 25vw;
  margin-left: 10px;
}

.main-menu {
  z-index: 100;
  position: absolute;
  left: 0;
  bottom: 5px;
  background-color: transparent;
  margin-left: 10px;
}

.layer-button-stack {
  z-index: 100;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background-color: white;
}

.MuiAutocomplete-root input {
  font-weight: 400;
  color: #6F6F71;
  background-color: lightgrey;
  border-radius: 6px;
}

hr {
  border: 0.25px solid black;
  width: 100%
}

.side-info {
  position: absolute;
  width: 400px;
  z-index: 100;
  right: 0;
  top: 0;
}

.air-quality-colors .color {
  display: inline-block;
  min-width: 100px;
  height: 1rem;
}

.color.good {
  background-color: #67E567;
}

.color.satisfactory {
  background-color: #FFF055;
}

.color.fair {
  background-color: #FFBB58;
}

.color.poor {
  background-color: #FE4543;
}

.color.very-poor {
  background-color: #B5468B;
}

.alternate-route {
  /* background: repeating-linear-gradient(to right, #F7FF00, #F7FF00 0 15px, #4a80f5 15px 30px); */
  background-color: '#ff6900';
}